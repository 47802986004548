import axios from 'axios'
import configuration from '@/configuration'
import app from '@/main'

const mainApi = axios.create({
  baseURL: configuration.apiUrl
})

mainApi.interceptors.request.use((config) => {
  const token = app.$store.getters.userToken

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
})

export const getUserData = async () => {
  const resp = await mainApi.get('/api/users/get_me')
  return resp.data
}

export const getUserVideoLists = async () => {
  const resp = await mainApi.get('/api/broadcast/video-lists')
  return resp.data
}

export const addLinkToVideoList = async (linkToVideo) => {
  const resp = await mainApi.post('/api/broadcast/video-lists/add-link', { linkToVideo })
  return resp.data
}

export const deleteLinkFromVideoList = async (linkToVideo) => {
  const resp = await mainApi.delete('/api/broadcast/video-lists/delete-link', { data: { linkToVideo } })
  return resp.data
}

export const getRoutes = async (linkToVideo) => {
  const resp = await mainApi.get('/api/user-broadcast-routes')
  return resp.data
}

export const saveRoute = async (body) => {
  const resp = await mainApi.post('/api/user-broadcast-routes/create', body)
  return resp.data
}

export const updateRoute = async (body) => {
  const resp = await mainApi.patch('/api/user-broadcast-routes/update', body)
  return resp.data
}

export const deleteRoute = async (_id) => {
  const resp = await mainApi.delete('/api/user-broadcast-routes/delete', { data: { _id } })
  return resp.data
}
