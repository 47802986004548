import Vuex from 'vuex'
import Vue from 'vue'
import VueCookies from 'vue-cookies'
import jwtDecode from 'jwt-decode'
import { SpeedAndDistance } from '@trainerday/cycling-converter'
import {
  getUserData,
  getUserVideoLists,
  getRoutes,
  saveRoute,
  updateRoute,
  deleteRoute,
  addLinkToVideoList,
  deleteLinkFromVideoList
} from '@/data'

Vue.use(Vuex)

const token = VueCookies.get('token')

let tokenPayload

try {
  tokenPayload = jwtDecode(token)
} catch (e) {
  tokenPayload = {}
}

const {
  roles = []
} = tokenPayload

const current = {
  buildVersion: '',
  targetWatts: 0,
  averageWatts: 0,
  averageSegmentWatts: 0,
  averageBpm: 0,
  currentSecond: 0,
  currentSegmentIndex: 0,
  sportType: 'bike',
  statusActivity: 'ready',
  startDateLocal: null,
  bpm: 0,
  rpm: 0,
  ftp: 0,
  watts: 0,
  mode: '',
  segmentText: '',
  speed: 0,
  rpmTarget: { min: 0, max: 0 },
  isPlaySound: false, // deprecated after 3.5.4 ver
  soundEffect: 0,
  nextIntervalText: undefined,
  distance: 0,
  devices: {
    connected: 0,
    powerMeter: false,
    heartRateMeter: false,
    cadenceMeter: false
  },
  time: {
    total: 0,
    interval: 0,
    isTotalUp: false,
    isIntervalUp: false
  },
  params: {
    bikeWeight: 8,
    dragCoefficient: 0.6,
    riderWeight: 80,
    units: 'metric',
    locale: 'en'
  },
  workout: {
    segments: []
  }
}
export default new Vuex.Store({
  state: {
    history: {
      bpm: [],
      rpm: [],
      ftp: null,
      watts: [],
      segments: []
    },
    user: {
      userId: null,
      thresholdHR: 140,
      token: token || null,
      roles,
      isLogged: false,
      chart: true,
      chartInBottomBar: false,
      youTubeFrame: false,
      youTubeList: false,
      showRouteMap: false,
      hasLoaded: false
    },
    userRoute: {
      isLoaded: false,
      _id: null,
      coordinates: [],
      lastRideFinishCoordinates: null,
      lastRide: {
        startDateLocal: null,
        totalDistanceMeters: null,
        coordinates: []
      }
    },
    current: { ...current },
    videoList: [],
    gMapsHasLoaded: false
  },
  getters: {
    gMapsHasLoaded: state => state.gMapsHasLoaded,
    userRoute: state => state.userRoute,
    userToken: state => state.user.token,
    isBetaUser: (state) => state.user.roles ? state.user.roles.filter(e => e.includes('beta')).length > 0 || state.user.roles.includes('administrator') : false
  },
  mutations: {
    setGMapsHasLoaded(state, val) {
      state.gMapsHasLoaded = val
    },
    setHasLoaded(state) {
      state.user.hasLoaded = true
    },
    toggleDisplayRouteMap(state) {
      state.user.showRouteMap = !state.user.showRouteMap
    },
    showYoutubeFrame(state) {
      state.user.youTubeFrame = !state.user.youTubeFrame
      state.user.chart = !state.user.chart
      state.user.chartInBottomBar = !state.user.chart

      if (!state.user.youTubeList || state.user.youTubeFrame) {
        state.user.chart = false
        state.user.chartInBottomBar = true
      }
      state.user.youTubeList = !state.user.youTubeList
    },
    showYoutubeVideo(state) {
      state.user.youTubeFrame = !state.user.youTubeFrame
      state.user.chart = false
      if (state.user.youTubeList) {
        state.user.youTubeFrame = true
        state.user.youTubeList = !state.user.youTubeList
        state.user.chartInBottomBar = true
      }
    },
    showChart(state) {
      state.user.chart = true
      state.user.chartInBottomBar = false
      state.user.youTubeFrame = false
      state.user.youTubeList = false
    },
    setUserData(state, { userId, thresholdHR }) {
      state.user.userId = userId
      state.user.thresholdHR = thresholdHR || 140
      state.user.isLogged = true
      state.user.chart = true
    },
    setVideoList(state, videos) {
      state.videoList = videos
    },
    addVideo(state, data) {
      state.videoList.unshift(data)
    },
    deleteVideo(state, url) {
      state.videoList = state.videoList.filter((el) => el.url !== url)
    },
    setHistory(state, data) {
      state.history = {
        bpm: data.bpm || [],
        rpm: data.rpm || [],
        ftp: data.ftp || 0,
        watts: data.watts || [],
        segments: data.segments || state.current.workout.segments || []
      }
    },
    updateHistory(state, data) {
      state.history.bpm.push(data.bpm)
      state.history.rpm.push(data.rpm)
      state.history.ftp = data.ftp
      state.history.watts.push(data.watts)
      state.history.segments = data.segments
    },
    clearHistory(state) {
      state.history = {
        bpm: [],
        rpm: [],
        ftp: null,
        watts: [],
        segments: []
      }
    },
    clearCurrent(state) {
      state.current = { ...current }
    },
    clearUserRoute(state) {
      state.userRoute.isLoaded = false
      state.userRoute._id = null
      state.userRoute.coordinates = []
      state.userRoute.lastRideFinishCoordinates = null
      state.userRoute.lastRide = {
        startDateLocal: null,
        totalDistanceMeters: null,
        coordinates: []
      }
    },
    setCurrent(state, data) {
      if (data.sportType === 'bike') {
        const { velocity, totalDistanceMeters } = new SpeedAndDistance({
          watts: state.history.watts,
          units: state.current.params ? state.current.params.units : 'metric',
          riderWeight: state.current.params ? state.current.params.riderWeight : 80,
          bikeWeight: state.current.params ? state.current.params.bikeWeight : 8,
          dragCoefficient: state.current.params ? state.current.params.dragCoefficient : 0.6
        })
        data.speed = velocity[velocity.length - 1] || 0
        data.distance = totalDistanceMeters
      }
      state.current = Object.assign({ params: { ...state.current.params } }, data)
    },
    setUserRoute(state, routeData) {
      const { _id, coordinates, lastRide } = routeData
      state.userRoute._id = _id
      state.userRoute.coordinates = coordinates
      if (lastRide) {
        const { coordinates } = lastRide
        state.userRoute.lastRide = lastRide
        state.userRoute.lastRideFinishCoordinates = coordinates[coordinates.length - 1]
      }
      state.userRoute.isLoaded = true
    },
    clearRouteLastRide(state) {
      state.userRoute.lastRideFinishCoordinates = null
      state.userRoute.lastRide.startDateLocal = null
      state.userRoute.lastRide.totalDistanceMeters = null
      state.userRoute.lastRide.coordinates = []
    }
  },
  actions: {
    showYoutubeFrame({ commit }) {
      commit('showYoutubeFrame')
    },
    showYoutubeVideo({ commit }) {
      commit('showYoutubeVideo')
    },
    showChart({ commit }) {
      commit('showChart')
    },
    setHasLoaded({ commit }) {
      commit('setHasLoaded')
    },
    async checkUserId({ commit, state }, payload) {
      const { userIdFromQuery } = payload
      const data = await getUserData()
      const userIdFromToken = data.userId
      if (Number(userIdFromQuery) === userIdFromToken) {
        commit('setUserData', { userId: userIdFromToken, thresholdHR: data.thresholdHR })
      }
      commit('setHasLoaded')
    },
    async getVideoList({ commit }) {
      const data = await getUserVideoLists()
      commit('setVideoList', data)
    },
    async addVideo({ commit }, linkToVideo) {
      try {
        const data = await addLinkToVideoList(linkToVideo)
        const { title, durationSec, previewImageUrl } = data
        if (title === 'Error') return { title: 'Error' }
        commit('addVideo', { url: linkToVideo, title, duration: durationSec, previewImageUrl })
        return data
      } catch (e) {}
    },
    async deleteVideo({ commit }, linkToVideo) {
      await deleteLinkFromVideoList(linkToVideo)
      commit('deleteVideo', linkToVideo)
      return linkToVideo
    },
    async getRoute({ commit, state }) {
      const data = await getRoutes()
      if (data) {
        commit('setUserRoute', data)
      }
    },
    async saveRoute({ commit, state }, body) {
      const data = await saveRoute(body)
      commit('setUserRoute', data)
    },
    async updateRoute({ commit, state }, body) {
      const data = await updateRoute(body)
      commit('setUserRoute', data)
    },
    async deleteRoute({ commit, state }) {
      await deleteRoute(state.userRoute._id)
      commit('clearUserRoute')
    },
    loadGMaps({ commit, getters }) {
      if (!getters.gMapsHasLoaded) {
        const key = 'AIzaSyDkQGpKJO2R2K7GA2JeoDqcIVW8Hc_ulkc'
        const script = document.createElement('script')
        script.src = `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=geometry&v=weekly&callback=Function.prototype`
        script.async = true
        document.head.appendChild(script)
        commit('setGMapsHasLoaded', true)
      }
    }
  }
})
